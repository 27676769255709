<template>
  <div class="main">
    <div class="flex colum">
      <div>
        <div class="flex">
          <div>
            <img class="img-responsive" src="../../assets/m1.png" alt="" />
          </div>
          <div>
            <img class="img-responsive" src="../../assets/m2.png" alt="" />
          </div>
        </div>
        <div>
          <img class="img-responsive" src="../../assets/m8.png" alt="" />
        </div>
      </div>
      <div>
        <img class="img-responsive" src="../../assets/m9.png" alt="" />
      </div>
    </div>
    <div>
      <img class="img-responsive middle-img" src="../../assets/m3.png" alt="" />
    </div>

    <div class="flex colum">
      <div>
        <img
          class="img-responsive bottom-img"
          src="../../assets/m10.png"
          alt=""
        />
      </div>
      <div>
        <div class="flex">
          <div>
            <img
              class="img-responsive square-img"
              src="../../assets/m4.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-responsive square-img"
              src="../../assets/m5.png"
              alt=""
            />
          </div>
        </div>
        <div class="flex">
          <div>
            <img
              class="img-responsive square-img"
              src="../../assets/m6.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-responsive square-img"
              src="../../assets/m7.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 px-4">
    <div class="row">
        <div class="col-md-5 col-xs-12">
        <div class="row">
          <div class="col-md-6">
            <div class="img_box">
            <img
              class="img-responsive square-img"
              src="../../assets/osd-2c.jpg"
              alt=""
           />
          </div>
          </div>
          <div class="col-md-6">

            <div class="img_box">
            <img
              class="img-responsive square-img"
              src="../../assets/osd-7c.jpg"
              alt=""
            />
          </div>
</div>
<div class="col-md-12">
  <div class="img_box">
            <img
              class="img-responsive square-img"
              src="../../assets/osd-8c.jpg"
              alt=""
            />
          </div>
</div>
        </div>  
        </div>
        <div class="col-md-7"><div class="main_imag_box">
        <img
          class="img-responsive bottom-img" 
          src="../../assets/OmanSportsDay-1.jpg"
          alt=""
           />
      </div></div>
</div>
</div>
    <div class="flex colum">
      
<div>
        <div class="flex">
     
        </div>
        <div class="flex">
            
        </div>
      </div>
      
    </div>
    <div>
      <img class="img-responsive middle-img" src="../../assets/osd-3c.jpg" alt="" />
    </div>
    <div class="flex colum">
      <div>
        <div class="flex">
          <div>
            <img class="img-responsive osd5" src="../../assets/osd-4c.jpg" alt="" />
          </div>
        </div>
        <div>
          <img class="img-responsive osd5" src="../../assets/osd-6c.jpg" alt="" />
        </div>
      </div>
      <div>
        <img class="img-responsive osd4" src="../../assets/osd-5c.jpg" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.main {
  margin-top: 20px;
}
.img-responsive {
  border-radius: 10px;
  margin: 15px 20px 15px 20px;
  width: 96%;
  display: flex;
}
.middle-img {
  width: 94%;
  margin-left: 4rem;
}
.bottom-img {
  width: 98%;
}
.img_box{
max-height: 250px;
}
.main_imag_box {
  width: 100%!important;
  height: 470px;
}
.main_imag_box img{

  height: 470px;
}
.square-img {
    margin: 10px 20px 0px 20px;
    width: 100%;
    height: 231px;
    background-size: cover;
    object-fit: cover;
}
.flex {
  display: flex;
  flex-wrap: nowrap;
  width: 98%;
  margin: auto;
  justify-content: space-evenly;
}
.osd4{
  height: 540px;
  width: 778px;
}
.osd5{
  height: 260px;
}
@media screen and (max-width: 1440px) {
  .middle-img {
    width: 96%;
    margin-left: 2rem;
  }
}
@media screen and (max-width: 1140px) {
  .colum {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .flex {
    display: block;
  }
  .img-responsive {
    margin: 15px 0px 15px 10px;
  }
  .middle-img {
    margin-left: 1rem;
  }
}
</style>