<template>
  <div
    class="row p-5 row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 g-4 mx-auto mb-5"
  >
  <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #da9d94"></div>
        <img src="../../assets/adil.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Mr. Kashif Nadeem</h1>
          <p class="lowertext">CEO<br>American Lyceum Private School LLC, Oman</p>
          <p class="lowertext"></p>
          <p class="card-text">
            <span v-if="showMore1">
              As the CEO of American Lyceum Private School LLC in Oman, Kashif Nadeem assumes a 
              pivotal leadership role, guiding the institution towards unprecedented achievements 
              in the realm of education. With an illustrious career spanning over 25 years, Kashif has 
              distinguished himself as a trailblazer in the Information Technology sector, leveraging 
              his expertise and innovation to drive progress. His journey is marked by significant 
              experiences that have enriched his diverse skill set and positioned him as a visionary leader 
              in the educational landscape.
              <span @click="showMore1 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore1 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card h-100 last_card">
        <div class="card-header" style="background-color: #c77d30"></div>
        <img src="../../assets/MubarakAlRahfiani.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Mr. Mubarak Al Rahbi</h1>
          <p class="lowertext">Director Oman</p>
          <!-- <p class="card-text">
            <span v-if="showMore6">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Tempore quis accusantium nobis dolores ex beatae nisi reprehenderit 
              doloribus! Reiciendis vitae ratione deleniti omnis sed suscipit eum minima. 
              Natus, ratione nobis!<span
                class="more"
                @click="showMore6 = false"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore6 = true" class="more"
              ><br />
              View Detail...</span
            >
          </p> -->
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #d8655b"></div>
        <img src="../../assets/dylon.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Dylon Loch</h1>
          <p class="lowertext">International, Business and HR Development</p>
          <p class="card-text">
            <span v-if="showMore">
              Highly motivated individual with extensive experience in sales and
              marketing. A proven track record of success as an individual
              contributor and people manager with comprehensive knowledge of the
              intricate balance in aligning the goals of a company with its
              clients and shareholders alike. Goal-oriented individual with a
              deep understanding of the cross-functional operations required to
              scale an idea into a reality. Excellent public speaker who thrives
              in team environments.
              <span @click="showMore = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card h-100 lastcard">
        <div class="card-header" style="background-color: #d8655b"></div>
        <img src="../../assets/aisha.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Ms. Aisha Hassan</h1>
          <p class="lowertext">GM, Oman</p>
          <p class="card-text">
            <span v-if="showMore4">
              Ms.Hassan has 21 years of experience in Education Leadership. She
              has been with the American Lyceum International School for the
              last twelve years. She is heading to expand the network of schools
              in the Gulf region. She holds a bachelor's and a master's degree
              in Educational Leadership and management.exemplifies positive,
              professional leadership that makes a difference in the lives of
              the students and the staff. She is always focused on student
              success.
              <span @click="showMore4 = false" class="more"
                >show less...</span
              ></span
            >
            <span class="more" v-else @click="showMore4 = true"
              ><br />
              View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card h-100 lastcard">
        <div class="card-header" style="background-color: #d8655b"></div>
        <img src="../../assets/farasat.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Mr. Farasat Ali</h1>
          <p class="lowertext">Director Finance, Oman</p>
          <p class="card-text">
            <span v-if="showMore5">
              Mr. Muhammad Farasat Ali has been Senior Manager at the American Lyceum International 
              School for the last 15 years. Currently, he is giving his expertise in overseas branches. 
              He holds a bachelor's degree in Commerce & Law. He has provided leadership and support to 
              his colleagues informally over the years. His steady approach and reflective nature make 
              him approachable to all.<span
                class="more"
                @click="showMore5 = false"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore5 = true" class="more"
              ><br />
              View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #09a983"></div>
        <img src="../../assets/tahir.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Tahir N. Qadri</h1>
          <p class="lowertext">Harvard University, Director/GM, UK</p>
          <p class="card-text">
            <span v-if="showMore0">
              Mr. Qadri is a visionary leader with a keen sense of strategy and
              forward-thinking. He possesses more than two decades of extensive
              experience in leading, educational designing, developing, and
              implementing educational programs in the education sector. He
              hopes to inspire sustainable leadership today. He is an
              internationally recognized speaker.
              <span @click="showMore0 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore0 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card h-100 secondlastcard">
        <div class="card-header" style="background-color: #09a983"></div>
        <img src="../../assets/amber.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Ms. Amber Shah</h1>
          <p class="lowertext">Manager, UK</p>
          <p class="card-text">
            <span v-if="showMore3">
              Amber is a committed professional with over 18 years of
              progressive and professional experience in Academia, Capacity
              Building, Life Long Learning and Business & Project Management /
              Development. She has gained an extensive expertise in Project
              Management, Professional Trainings, Community Development &
              Progressive Lifelong learning while working mainly with
              employers/clients like UK Universities/FE Sector, Construction
              Industry, and Training Organisations in the UK and in the
              Subcontinent. She has been involved in developing / conducting
              Trainings Nationally & Internationally, managing/coordinating
              regional projects and providing technical advisory services to
              local NGOs in strategic and program planning in education &
              training sector mainly youth focused. Amber is a Pearson National
              Silver Award Winner (2016) in the UK and is looking after the
              European Ventures of the Lyceum Group.<span
                @click="showMore3 = false"
                class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore3 = true" class="more"
              ><br />
              View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #c77d30"></div>
        <img src="../../assets/sumara.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Ms. Sumaira Duad</h1>
          <p class="lowertext">Curriculum Developer, UK</p>
          <p class="card-text">
            <span v-if="showMore2">
              Ms. Daud, having major in Educational Psychology she has completed
              certification courses on Teachers Training and Educational
              Management conducted by Oxford University Press and the Government
              of Punjab, Pakistan. Have been involved in school leadership
              development. She has vast experience of developing and evaluating
              efficient management system for organizations particularly
              schools.
              <span @click="showMore2 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore2 = true" class="more"
              ><br />
              View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>

  </div>
</template>
<script setup>
import { ref } from "vue";
let showMore0 = ref(false);
let showMore = ref(false);
let showMore1 = ref(false);
let showMore2 = ref(false);
let showMore3 = ref(false);
let showMore4 = ref(false);
let showMore5 = ref(false);
// let showMore6 = ref(false);
</script>

<style scoped>
.more {
  color: green;
  cursor: pointer;
}
.card {
  margin-top: 3%;
  width: 95%;
  border-radius: 12px;
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 70%;
  margin: auto;
}
.Main {
  transform: translateX(-30vw);
  margin-top: -18vw;
  margin-bottom: 20vw;
}

.childimage {
  width: 100%;
}

.advisorytext {
  font-weight: 600;
  font-size: 80px;
  line-height: 66px;
}

.boardtext {
  font-weight: 600;
  font-size: 120px;
  line-height: 120px;
}

.smalltext {
  font-family: "poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  transform: translateX(10vw);
}

.card-text {
  font-family: "Catamaran";
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  font-family: serif;
  text-align: justify;
  color: #787878;
  justify-content: center;
  display: flex;
}

.bootomtext {
  font-family: "Catamaran";
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}
.lowertext {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}
.secondlastcard {
  margin-left: 0vw;
  /* width: 100%; */
}

.lastcard {
  margin-left: 0vw;
  /* width: 100%; */
}
.last_card {
  margin-left: 0vw;
  /* width: 100%; */
}

/* Media Quires Start */
@media screen and (min-width: 1400px) {
  .advisorytext {
    font-size: 56px;
    line-height: 29px;
  }

  .boardtext {
    font-size: 89px;
    line-height: 100px;
  }
}

@media screen and (max-width: 992px) {
  .secondlastcard {
    margin-left: 0vw;
  }

  .lastcard {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 768px) {
  .secondlastcard {
    margin-left: 0vw;
  }

  .lastcard {
    margin-left: 0vw;
  }
}
</style>
