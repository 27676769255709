<template>
  <div class="body">
    <div class="main">
      <div class="container">
        <h1 class="heading1">Our</h1>
        <h2 class="heading2">unique learning enviroment</h2>
        <p class="paragraph">
          We provide the environments where infants and toddlers can have secure
          relationships and interactions with grown-ups who are mindful and
          responsive, have a good sense of reassurance, and are allowed to
          explore and learn.
        </p>
      </div>
      <!-- <div class="d-flex col-12">
	<div class="col-lg-4"></div>
<div class="col-lg-4"><img src="../assets/one.png"></div>
<div class="col-lg-4"><img src="../assets/three.png"></div>
	</div>
	<div class="d-flex col-12">
	<div class="col-lg-6"></div>
<div class="col-lg-3"><img src="../assets/two.png"></div>
<div class="col-lg-3"><img src="../assets/four.png"></div>
	</div> -->
    </div>
  </div>
</template>
<style scoped>
.body {
  margin-bottom: 5%;
}
.main {
  position: relative;
  background-image: url("../../assets/learn.png");
  /* height: 125vh;
  width: 100%;
  background-size: cover; */
  /* margin-top: 10% !important;
  margin-bottom: 8% !important; */
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 135vh;
  /* border: 1px solid; */
}
.heading1 {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 900;
  font-size: 150px;
  line-height: 129px;
  color: #0a303a;
}
.heading2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 68px;
  color: #0a303a;
  padding: 0px 0px 0px 160px;
  white-space: nowrap;
}
.container {
  padding: 100px 0px 0px 80px;
  width: 71%;
  /* border: 1px solid; */
  margin-top: 100px;
}
.paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  text-align: justify;
  padding: 0px 0px 0px 160px;
}
@media screen and (max-width: 992px) {
  .heading1 {
    font-size: 100px;
    line-height: 80px;
  }
  .heading2 {
    font-size: 44px;
    padding: 0px;
  }
  .paragraph {
    padding: 0px;
  }
}
@media screen and (max-width: 992px) {
  .container {
    padding: 80px 60px 0px 60px;
    margin: auto;
    width: fit-content;
    background: #d5e6eb;
    opacity: 90%;
  }
  .main {
    padding: 32px;
  }
  .d-flex {
    width: 100%;
    justify-content: space-around;
  }
}
@media screen and (max-width: 768px) {
  .heading2 {
    font-size: 34px;
  }
}
@media screen and (max-width: 628px) {
  .heading1 {
    font-size: 80px;
  }
  .heading2 {
    font-size: 24px;
    white-space: normal;
  }
  .paragraph {
    font-size: 16px;
  }
  .container {
    width: 100%;
    margin: auto;
    padding: 0px;
  }
}
</style>