<template>
  <div class="row" id="vision">
    <div class="col-12 col-lg-4 md-6 sm-12 grid">
      <img class="tvimg" src="../../assets/tv.png" />
    </div>
    <div class="col-12 col-lg-5 md-6 sm-12 grid">
      <div class="container">
        <h1 class="heading1">About Us</h1>
        <h2 class="heading2">Vision</h2>
        <p class="paragraph">
          By Cultivating the crop of Enlightened leaders become the name of
          trust pride and performance
        </p>
        <h2 class="heading2">Philosophy</h2>
        <p class="paragraph">
          Nursery strives to be preschool of choice by providing learning
          environment suitable for children development
        </p>
        <h2 class="heading2">Mission</h2>
        <p class="paragraph">
          To Enable Primary & Secondary Greatness In People
        </p>
        <!-- <div class="btn btn-outline-success">
          Explore<br />
          more
        </div> -->
      </div>
    </div>

    <div class="col-12 col-lg-3 md-6 sm-12 grid">
      <img class="mtherimg" src="../../assets/mother.png" />
    </div>
  </div>
</template>

<style scoped>
.btn {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
}
.heading1 {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 900;
  font-size: 90px;
  line-height: 129px;
  color: #448b83;
  white-space: nowrap;
}
.heading2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 68px;
  color: #448b83;
}
.paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  text-align: justify;
}
.row {
  width: 90%;
  margin-top: 10% !important;
  margin-bottom: 8% !important;
  margin: auto;
}
@media screen and (max-width: 1198px) {
  .tvimg {
    width: 30vw;
  }
  .mtherimg {
    width: 25vw;
  }
}
@media screen and (max-width: 992px) {
  .grid {
    width: fit-content;
    margin: auto;
  }
  .heading1 {
    font-size: 60px;
    line-height: 100px;
  }
  .heading2 {
    font-size: 35px;
    line-height: 55px;
  }
  .tvimg {
    width: 45vw;
  }
  .mtherimg {
    width: 35vw;
  }
}
</style>
