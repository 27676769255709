<template>
<div class="main">
    <div class="body">
        <h1 class="headtext">Services</h1>
        <div class="col-lg-6">
          <p class="bodytext">
            
                  Education services in the nature of early childhood instruction; Education services, namely, providing kindergarten through 12th grade (K-12) classroom instruction; Education services, namely, providing pre-kindergarten through 12th grade (pre-K-12) classroom instruction; Education services, namely, providing pre-kindergarten through 12th grade classroom instruction at international schools; Education services, namely, providing pre-school ...
              
          </p>
      </div>
    </div>
</div>
</template>

<style scoped>
.main {
    position: relative;
    background-image: url("../../assets/projhead.png");
    height: 570px;
    background-size: cover;
    background-repeat: no-repeat;
}

.body {
    padding: 225px 0 0 100px;
}

.headtext {
    font-family: "Catamaran";
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 66px;
    margin-top: 30px;
    align-items: center;
    color: #0a303a;
}

.bodytext {
    font-family: "Catamaran";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    margin-top: 30px;
    align-items: center;
    color: #333333;
}

@media screen and (max-width: 992px) {
    .body {
        padding: 225px 0 0 30px;
    }

    .headtext {
        font-size: 85px;
    }
}

@media screen and (max-width: 767px) {
    .headtext {
        font-size: 75px;
    }
}
</style>
