import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../pages/LandingPage.vue';
import Projects from '../pages/ProjectsPage.vue';
import UpComing from '../pages/UpComingProject.vue';
import Contactus from '../pages/ContactUsPage.vue';
import OurTeam from '../pages/OurTeam.vue';
import ManagementTeam from '../pages/ManagementTeam.vue';
import Awards from '../pages/AwardsPage.vue';
import Advertisment from '../pages/AdsPage.vue';
import EventPage from '../pages/EventsPage.vue';
import Gallery from '../pages/GalleryPage.vue';
import Career from '../pages/CareerPage.vue';
import CareerDetail from '../pages/CareerDetail.vue';
import ServicesPage from '../pages/ServicesPage.vue';


import RegisterLondon from '../pages/Registration/RegisterLondon.vue';
import RegisterManchster from '../pages/Registration/RegisterManchster.vue';
import NewsPage from '../pages/NewsPage.vue';


const routes = [{
        path: '/',
        name: 'home',
        component: LandingPage,
        meta: {
            title: 'AmericanLyceum ||Uk'
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: Projects,
        meta: {
            title: 'AmericanLyceum ||Projects'
        }
    },
    {
        path: '/UpComing',
        name: 'UpComing',
        component: UpComing,
        meta: {
            title: 'AmericanLyceum ||UpComing-Projects'
        }
    },
    {
        path: '/Contactus',
        name: 'Contactus',
        component: Contactus,
        meta: {
            title: 'AmericanLyceum ||Contact'
        }
    },
    {
        path: '/OurTeam',
        name: 'OurTeam',
        component: OurTeam,
        meta: {
            title: 'AmericanLyceum ||Team'
        }
    },
    {
        path: '/ManagementTeam',
        name: 'ManagementTeam',
        component: ManagementTeam,
        meta: {
            title: 'AmericanLyceum ||Management'
        }
    },
    {
        path: '/Awards',
        name: 'Awards',
        component: Awards,
        meta: {
            title: 'AmericanLyceum ||Awards'
        }
    },
    {
        path: '/services',
        name: 'ServicesPage',
        component: ServicesPage,
        meta: {
            title: 'AmericanLyceum ||Services'
        }
    },
    {
        path: '/events',
        name: 'EventPage',
        component: EventPage,
        meta: {
            title: 'AmericanLyceum || Events'
        }
    },
    {
        path: '/advertisment',
        name: 'Advertisment',
        component: Advertisment,
        meta: {
            title: 'AmericanLyceum || Advertisment'
        }
    },
    {
        path: '/Gallery',
        name: 'Gallery',
        component: Gallery,
        meta: {
            title: 'AmericanLyceum ||Gallery'
        }
    },
    {
        path: '/career',
        name: 'Career',
        component: Career,
        meta: {
            title: 'AmericanLyceum || Career'
        }
    },
    {
        path: '/post-detail/:slug',
        name: 'CareerDetail',
        component: CareerDetail,
        meta: {
            title: 'AmericanLyceum || Career detail'
        }
    },

    {
        path: '/register-london',
        name: 'RegisterLondon',
        component: RegisterLondon,
        meta: {
            title: 'AmericanLyceum || Register London'
        }
    },
    {
        path: '/register-manchster',
        name: 'RegisterManchster',
        component: RegisterManchster,
        meta: {
            title: 'AmericanLyceum || Register Manchster'
        }
    },
    {
        path: '/news',
        name: 'NewsPage',
        component: NewsPage,
        meta: {
            title: 'AmericanLyceum || News'
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return { el: to.hash };
        }
        return { x: 0, y: 0 }
    },
    
});
router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
});

export default router