<template>
  <section>
    <footer>
      <div class="footer-content">
        <div class="list" style="margin-top: 30px;">
          <ul>
            <!-- <li>
              <router-link to="/" class="customultext1">Home</router-link>
            </li> -->
            <li>
              <router-link to="/projects" class="customultext1"
                >Projects</router-link
              >
            </li>
            <li>
              <router-link to="/Gallery" class="customultext1"
                >Gallery</router-link
              >
            </li>
            <!-- <li class="customultext1">Admission</li>
            <li class="customultext1">Programs</li>
            <li class="customultext1">School Calender</li> -->
          </ul>
        </div>

        <div class="list" style="margin-top: 30px;">
          <ul>
            <!-- <li>
              <router-link to="/Awards" class="customultext1"
                >Awards</router-link
              >
            </li> -->
            <li>
              <router-link to="/ManagementTeam" class="customultext1"
                >Our Team</router-link
              >
            </li>
            <li>
              <router-link to="/Contactus" class="customultext1"
                >Contact Us</router-link
              >
            </li>
            <!-- <li class="customultext1">Blog</li>
            <li class="customultext1">About</li>
            <li class="customultext1">Location</li> -->
          </ul>
        </div>

        <div class="list" style="margin-top: 5px;">
          <div>
            <router-link to="/">
              <img class="logoimage" src="https://thelyceumgroup.s3.ap-southeast-1.amazonaws.com/alisuk/logoalisuk.png" style="height:10vw; width:10vw;"  />
            </router-link>
          </div>
          <div class="customtext">
            
            <!-- <p class="maintext">(PVT)</p> -->
          </div>
        </div>

        <div class="list" style="line-height: 10px">
          <ul>
            <li class="customultext1" style="font-size: 20px;"><strong><u>United Kingdom</u></strong></li>
            <li class="customultext1">Royal Wharf,</li>
            <li class="customultext1">London,</li>
            <li class="customultext1">United Kingdom</li>
            <li class="customultext2"><a href="https://maps.app.goo.gl/1c2JkgcZW5mkHQTt8"> Google Map</a></li>
            <li class="customultext1">
              Phone: <span class="customultext2">+44 778 0000138</span>
            </li>
            <li class="customultext2">info@americanlyceum.uk</li>
          </ul>
        </div>
        <div class="list" style="line-height: 10px">
          <ul>
            <li class="customultext1" style="font-size: 20px;"><strong><u>Oman</u></strong></li>
            <li class="customultext1">Muscat,</li>
            <li class="customultext1">American Lyceum Private School</li>
            <li class="customultext1">Building 4078, Way 4864, Block No 248,</li>
            <li class="customultext1">North Aludhaybah, Bousher, Muscat</li>
            <li class="customultext2">Sultunate of Oman </li>
            <li class="customultext1">
              Phone: <span class="customultext2">+968-90856281</span>
            </li>
            <li class="customultext2">gmoman@americanlyceum.com</li>
          </ul>
        </div>

        <div class="list">
          <div>
            <router-link to="/Contactus"
              ><button type="button" class="btn btn-sm">
                Contact Us
              </button></router-link
            >
          </div>
          <div class="bottom">
            <!-- <img class="bootomicon" src="../../assets/INS.svg" />
            <img class="bootomicon" src="../../assets/FB.svg" />
            <img class="bootomicon" src="../../assets/YT.svg" /> -->
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<style scoped>
.btn {
  background-color: #e74c25;
  color: white;
  font-family: Poppins;
  font-size: 20px;
  border-radius: 5px;
}
.customultext1 {
  color: black;
  text-decoration: none;
}
.customultext2 {
  color: #e74c25;
}
.maintext {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}
.bottom {
  margin: 40px 0 0px 0px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}
</style>
<style scoped>
* {
  font-family: "Poppins", sans-serif;
}

footer {
  background-image: url("../../assets/footer.png");
  min-height: 350px;
  /* border: 1px transparent solid; */
  background-size: cover;
}
footer .footer-content {
  width: 90%;
  margin: 50px auto 0 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 249px;
}
footer .footer-content .logo-area > img {
  width: 80%;
  margin-bottom: 10px;
}

footer .footer-content .logo-area {
  color: white;
  width: 250px;
  margin: 0px 20px;
}
footer .footer-content .logo-area > p {
  font-size: 10px;
}
footer .footer-content .info {
  font-size: 12px !important;
  margin-left: 20px;
}
footer .footer-content .info h6 {
  font-size: 12px;
}
footer .footer-content .info span {
  display: block;
  margin-bottom: 10px;
}
.list h5 {
  margin-bottom: 15px;
  color: white;
  font-size: 15px;
}
.list h5:after {
  content: "";
  display: block;
  width: 35px;
  height: 1px;
  background: #edda77;
  margin-top: 10px;
}
.list ul {
  list-style: none;
  padding: 0px;
  color: white;
}
.list ul li {
  font-size: 17px;
  margin-bottom: 22px;
}
.list form input,
.list form textarea {
  width: 100%;
  padding-left: 12px;
  background: #18212d;
  border: none;
  height: 38px;
  border-radius: 20px;
  outline: none;
  display: block;
  margin-bottom: 5px;
  line-height: 30px;
  color: #fff;
}
.list form textarea {
  resize: none;
  height: 100px;
}
.list form input::placeholder,
.list form textarea::placeholder {
  font-size: 13px;
}
.list form button,
.btnProceed {
  background: #004080;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 11px;
  color: white;
  font-weight: 500;
  margin-left: auto;
  display: block;
}
.socail-links {
  font-size: 23px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 1rem;
}

.socail-links:after {
  content: "";
  display: block;
  width: 35px;
  height: 3px;
  background: #edda77;
  margin-top: 10px;
}

footer .socail-links:after {
  border-radius: 20px;
}

.social-icons {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.social-icons i {
  font-size: 20px;
  margin-right: 20px;
  color: white;
}
.footer-content-detail {
  width: 90%;
  margin: 20px auto 0px;
}
.footer-content-detail h6 {
  color: #003f7e;
  font-weight: 800;
  font-size: 14px;
  margin: 0px;
}
.footer-content-detail p {
  font-size: 12px;
  color: white;
}
.footer-content-detail .copyright {
  font-size: 13px;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

/* other styling */
.info-text {
  font-size: 14px;
  letter-spacing: 0.52px;
  color: #ffffff;
  margin-bottom: 8px;
}

#contactHeading {
  font-size: 19px;
}
@media (max-width: 1200px) {
  /* .footer-content
    {
      width:95% !important;
    } */
}

@media (max-width: 1100px) {
  footer .footer-content {
    justify-content: space-evenly;
  }
  .list {
    /* margin-right: 50px; */
    margin-top: 20px;
  }
  footer .footer-content .logo-area {
    margin: 0px 50px 20px 0px;
  }
}
@media screen and (max-width: 992px) {
  footer .footer-content {
    margin: 0 0 0 30px;
  }
}
</style>
