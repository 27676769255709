<template>
  <div class="main">
    <div class="body">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed headtext"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What is American Lyceum and Toddlers?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body bodytext">
              American Lyceum Ltd, UK is the parent body and the toddlers in the trade name owned by
              American Lyceum Ltd, UK.
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingtwo">
            <button
              class="accordion-button collapsed headtext"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsetwo"
              aria-expanded="false"
              aria-controls="collapsetwo"
            >
              How can I get Admission?
            </button>
          </h2>
          <div
            id="collapsetwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingtwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body bodytext">
              Currently we are in the process of establishment of nursery. Hopefully
              we will be opening our doors in the 4th quarter of 2024. You can send your 
              interest at info@americanlyceum.uk and we will put you in the admission list. 
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingthree">
            <button
              class="accordion-button collapsed headtext"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsethree"
              aria-expanded="false"
              aria-controls="collapsethree"
            >
              Where are branches of toddlers in UK?
            </button>
          </h2>
          <div
            id="collapsethree"
            class="accordion-collapse collapse"
            aria-labelledby="headingthree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body bodytext">
              We are going to open our first branch in Royal Wharf,
              London. The other branches are being planned to be
              opened in Liverpool and Manchester. 
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingfour">
            <button
              class="accordion-button collapsed headtext"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsefour"
              aria-expanded="false"
              aria-controls="collapsefour"
            >
              What is Toddlers process on checking the background of their
              employees?
            </button>
          </h2>
          <div
            id="collapsefour"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body bodytext">
                  • Our employee check covers a series of steps including:
              <br>• Candidate's criminal records 
              <br>• Driving records 
              <br>• Whether they are on a terror watch list .
            </div>
          </div>
        </div>

        <!-- <div class="accordion-item">
          <h2 class="accordion-header" id="headingfive">
            <button
              class="accordion-button collapsed headtext"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsefive"
              aria-expanded="false"
              aria-controls="collapsefive"
            >
              What is your policy regarding pictures/videos of my child(ren)?
            </button>
          </h2>
          <div
            id="collapsefive"
            class="accordion-collapse collapse"
            aria-labelledby="headingfive"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body bodytext">
              We definitely have a proper policy regarding the pictures and
              videos of your children as we always protect your privacy concerns
              regarding your children
            </div>
          </div>
        </div> -->
      </div>

      <!-- <div class="button">
        <button class="btn">View all</button>
      </div> -->
    </div>
  </div>
</template>
<style scoped>
.main {
  position: relative;
  background-image: url("../../assets/back.png");
  min-height: 690px;
  margin-top: 55px;
  background-size: cover;
  background-repeat: no-repeat;
}
.accordion-button::after {
  margin-left: 25px;
}
.accordion-button::before {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background-image: url("../../assets/plus.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-right: 10px;
}
.accordion-button:not(.collapsed)::before {
  background-image: url("../../assets/minus.png");
  transform: rotate(-360deg);
}
.accordion-button::after {
  display: none;
}
.body {
  padding: 150px 0 0 235px;
  width: 75%;
  margin: 0 0 75px 0;
  /* display: flex;
  justify-content: center; */
}
.accordion-item {
  background: #f4f1ea;
  border: none;
}
.accordion-button:focus {
  z-index: 1;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0 rgb(13 110 253 / 25%);
}

.headtext {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  text-decoration: none;
  margin: 10px 0 0px 0px;
  /* identical to box height, or 100% */
  display: flex;
  align-items: center;
  color: #0a303a;
  background: #f4f1ea;
}
.bodytext {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  /* margin-top: 30px; */
  text-align: justify;
  align-items: center;
  color: #333333;
  background: #f4f1ea;
}
.btn {
  border: 1px solid #e74c25;
  color: #e74c25;
  font-style: italic;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
}
.button {
  width: 100%;
  margin: auto;
  padding: 20px 0 35px 0;
  justify-content: center;
  display: flex;
}
@media screen and (max-width: 992px) {
  .body {
    padding: 150px 15px 0 15px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .headtext {
    font-size: 20px;
  }
  .body {
    padding: 150px 15px 0 15px;
    width: 100%;
  }
}
</style>