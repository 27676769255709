<template>
  <div class="main">
    <div class="body">
      <h1 class="headtext" style="font-size: 70px;">Management Team</h1>
      <p class="bodytext">
        Top leadership is very crucial in the success of any organisation. American Lyceum is blessed 
        <br>to have the following highly committed leadership. 
      </p>
    </div>
  </div>
</template>
  
<style scoped>
.main {
  position: relative;
  background-image: url("../../assets/contacth.png");
  height: 620px;
  background-size: cover;
  background-repeat: no-repeat;
}

.body {
  padding: 225px 0 0 100px;
}

.headtext {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 120px;
  line-height: 66px;
  margin-top: 30px;
  align-items: center;
  color: #0a303a;
}

.bodytext {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-top: 30px;
  align-items: center;
  color: #333333;
}

@media screen and (max-width: 992px) {
  .body {
    padding: 225px 0 0 30px;
  }

  .headtext {
    font-size: 85px;
  }
}

@media screen and (max-width:767px) {
  .headtext {
    font-size: 54px;
  }
}
</style>