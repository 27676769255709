<template>
  <div class="main">
    <div class="container-fluid">
      <!-- <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="body">
            <div class="d-flex">
              <div>
                <h1 class="headtext" style="line-height: 25px">
                  American Lyceum <br />International School
                </h1>
              </div>
              <img class="lycemlogo" src="../../assets/alis.png" alt="" />
            </div>
            <p class="bodytext">
              American Lyceum International School (ALIS) is an entity
              incorporated in the State of Washington USA under the UBI number
              633-341-413. ALIS was founded by Engr. C.M. Nadeem Qadri and Mrs.
              Tahira Nasreen in the year 1984. Since then American Lyceum
              International School has achieved many milestones and has become
              the name of trust, pride and performance.<br />
              Currently, ALIS is having forty two branches and the number is
              continuously increasing. We emphasis more on quality 2017. ALIS is
              following the 'Leader In Me' program devised by Stephen R. Covey
              (1932- 2012), a distinguished educator author, businessman and a
              keynote speaker, ALIS is affiliated with different accreditation
              bodies including the University of Cambridge.
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="imgbody">
            <img class="img" src="../../assets/cardt.jpg" alt="" />
          </div>
        </div>
      </div> -->

      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="body">
            <div class="d-flex">
              <div>
                <h1 class="headtext" style="line-height: 25px">
                  American Lyceum Nurseries <br />Muscat, Oman
                </h1>
              </div>
              <img class="lycemlogo" src="../../assets/nlogo.png" alt="" />
            </div>
            <p class="bodytext">
              ALIS Muscat Nursery is integrated with movement, fine arts,
              character- based leadership and faith formation that are woven
              together into a seamless experience of personalized growth. As an
              independent school, we continue our founding traditions as an
              International community by using all the advance and latest
              teaching methodologies.
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="imgbody">
            <img style="border-radius: 10px; height: 400px; object-fit: cover;" class="img" src="../../assets/oman-nursery.jpg" alt="" />
          </div>
        </div>
      </div>

      <!-- <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="body">
            <div class="d-flex">
              <h1 class="headtext">Royal Lyceum</h1>
              <img class="lycemlogo" src="../../assets/royal.png" alt="" />
            </div>
            <p class="bodytext">
              Royal Lyceum, the School of Excellence is a project of Lyceum
              group of schools having its footprints in the field of education
              since 1984. Our top concern is the education and preparation of
              tomorrow's conscience leaders.<br />
              Our target is to inculcate quality education in the general
              population. We are here to serve the great citizens of this
              country by educating their youth and setting them up to become
              socially responsible citizens and professionals. Royal Lyceum, the
              School of Excellence inherits its work ethics and professional
              stature from its counterpart. Every organization has a purpose,
              and our purpose is to become the best educator through its
              enhanced teaching and management techniques.
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="imgbody">
            <img class="img" src="../../assets/roy.png" alt="" />
          </div>
        </div>
      </div> -->

      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="imgbodyrevert">
            <img class="img" src="../../assets/lady.png" alt="" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="bodyrevert">
            <div class="d-flex">
              <div>
                <h1 class="headtext" style="line-height: 25px">
                  Toddlers Nurseries by American Lyceum
                </h1>
              </div>
              <img class="lycemlogo" src="https://thelyceumgroup.s3.ap-southeast-1.amazonaws.com/alisuk/logoalisuk.png" alt="" />
            </div>
            <p class="bodytext">
              American Lyceum Ltd, UK has acquired the trade name of “Toddlers” and all nurseries 
              in UK will be started by American Lyceum Ltd trading as Toddlers. Ourfirst nursery will 
              be started in Royal Wharf, London, UK. The other nurseries are planned in Liverpool and Manchester. 
            </p>
            <div class="text-center">
              <a href="https://toddlersnursery.uk/" target="_blank">
                <img src="https://thelyceumgroup.s3.ap-southeast-1.amazonaws.com/toddlers/cgBiDNamhicZDhOVC23xbxUzPtY5QK4cetItEQd2.png" alt="" style="width: 25%;">
              </a>
              
              <h6 class="pt-2" style="opacity: 0.6;font-style: italic;">
                  Toddlers Nurseries UK, A Project of American Lyceum
                </h6>
            </div>
            <!-- <div class="btn btn-class">Learn more</div> -->
          </div>
        </div>
      </div>

      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="body">
            <div class="d-flex">
              <h1 class="headtext" style="line-height: 25px">
                  American Lyceum School <br />Muscat, Oman
                </h1>
              <img class="lycemlogo" src="../../assets/alis.png" alt="" />
            </div>
            <p class="bodytext">
              The American Lyceum School's has been providing great educational
              opportunities in very unique manner to its international audience
              by facilitating them through our kindergarten, Pre-school and
              elementary school that follows Cambridge Curriculum & Leader in Me
              Program. <br />
              Through developing natural passion for learning, students are
              nurtured and enabled to transform their habits to ensure life-long
              learning and reflection of advance future. The objective for
              reaching out international platforms revolves around our belief in
              serving international communities with greater wisdom, conviction,
              and compassion. The American Lyceum International School has been
              a beacon of excellence in education. Children in grades 1-4 thrive
              in an experiential environment,
            </p>
            <!-- <div class="btn btn-class">Learn more</div> -->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="imgbody">
            <img style="border-radius: 10px;" class="img" src="../../assets/card2.png" alt="" />
          </div>
        </div>
      </div>

      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="imgbodyrevert">
            <img class="img" src="../../assets/card.png" alt="" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="bodyrevert">
            <div class="d-flex">
              <h1 class="headtext">British Online School</h1>
              <img class="lycemlogo" src="../../assets/british.png" alt="" />
            </div>
            <p class="bodytext">
              British Lyceum is an international, Cambridge Global & ISO
              certified online education platform, which offers a wide range of
              exceptional virtual learning services for educational and
              professional development<br />
              The heart of our mission is to educate leaders of character who
              will value and improve the world they inherit. Here in a
              warm,inclusive culture, every child is known, their God-given
              talents are valued and relationships flourish.
            </p>
            <!-- <div class="btn btn-class">Learn more</div> -->
          </div>
        </div>
      </div>

      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="body">
            <div class="d-flex">
              <h1 class="headtext">E-School</h1>
              <img class="lycemlogo" src="../../assets/eschool.png" alt="" />
            </div>
            <p class="bodytext">
              E School is an Application to make world class education
              accessible to all on this planet. Now an international school is
              at your door steps. Everybody has the right to get a world class
              education. The whole content is based on the international
              curriculum. E School is a free online school management
              Application which is Responsive & optimized for all devices like
              Smartphones, Tablets etc.<br />Our target is to inculcate quality
              education in the general population. We are here to serve the
              great citizens of this country by educating their youth and
              setting them up to become socially responsible citizens and
              professionals. E-School, the School of Excellence inherits its
              work ethics and professional stature from its counterpart. Every
              organization has a purpose, and our purpose is to become the best
              educator through its enhanced teaching and management techniques.
            </p>
            <!-- <div class="btn btn-class">Learn more</div> -->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="imgbody">
            <img style="border-radius: 10px;" class="img" src="../../assets/esc.jpg" alt="" />
          </div>
        </div>
      </div>

      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="body">
            <div class="d-flex">
              <div>
                <h1 class="headtext" style="line-height: 27px">
                  <br />Brainstomer Technologies
                </h1>
              </div>
              <img class="lycemlogo" src="../../assets/brainstormer-logo-PNG.png" alt="" />
            </div>
            <p class="bodytext">
              Brainstormer Technologies, Provide all things in one roof like
              Desktop application, Web Application, Mobile Application. We
              provide services to customers in operational efficiency.
              Understanding client goals and the needs of their users. Our aim
              is to guide and enable our customers to innovate & change their
              business effectively.
            </p>
            <!-- <div class="btn btn-class">Learn more</div> -->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="imgbody">
            <img style="border-radius: 10px;" class="img" src="../../assets/office.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: relative;
  background-image: url("../../assets/background.png");
  /* height: 570px; */
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0px 0 0;
}
.body {
  padding: 0px 39px 15px;
  background: white;
  border-radius: 10px;
  width: 90%;
  text-align: justify;
  margin: 0 0 0 auto;
  z-index: 1;
  position: relative;
}
.bodyrevert {
  padding: 0px 39px 15px;
  background: white;
  border-radius: 10px;
  width: 90%;
  text-align: justify;
  margin: 0 0 0 -35px;
  z-index: 1;
  position: relative;
}
.lycemlogo {
  margin-top: 10px;
  height: 100px;
  width: 200px;
  object-fit: contain;
}
.d-flex {
  justify-content: space-between;
}
.imgbody {
  margin-left: -30px;
  margin-top: 130px;
}
.imgbodyrevert {
  /* width: 100%; */
  justify-content: right;
  display: flex;
  margin-top: 130px;
}
.card-bodys {
  display: flex;
  margin-top: 8%;
  margin-bottom: 20px;
}
.headtext {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 66px;
  /* margin-top: 30px; */
  align-items: center;
  color: #0a303a;
  padding-top: 20px;
}
.btn-class {
  border: 1px solid #e74c25;
  color: #e74c25;
  font-size: 14px;
  font-style: italic;
}
.bodytext {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-top: 10px;
  align-items: center;
  color: #333333;
  padding: 0 100px 0px 0;
}
@media screen and (max-width: 1542px) {
  .body {
    width: 100%;
  }
  .bodyrevert {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) {
  .img {
    width: -webkit-fill-available;
  }
}
@media screen and (max-width: 1320px) {
  /* .headtext {
    font-size: 25px;
  }
  .bodytext {
    font-size: 15px;
  } */
}
@media screen and (max-width: 1151px) {
  /* .headtext {
    font-size: 20px;
  }
  .bodytext {
    font-size: 16px;
    line-height: 20px;
  } */
}
@media screen and (max-width: 992px) {
  .card-bodys {
    display: flex;
    flex-direction: column;
  }
  .imgbody {
    margin-top: 50px;
    margin-left: 0px;
  }
  .body {
    margin-top: 30px;
  }
  .bodyrevert {
    margin: 0px;
    margin-top: 30px;
  }
  .bodytext {
    padding: 0px;
  }
  .headtext {
    line-height: 30px;
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  .main {
    padding: 100px 0 0 0;
  }
}
@media screen and (max-width: 482px) {
  .headtext {
    line-height: 20px !important;
  }
  .body {
    padding: 0px 15px 15px;
  }
  .bodyrevert {
    padding: 0px 15px 15px;
  }
  .lycemlogo {
    width: fit-content;
  }
}

.main h1
{
  font-size: 26px !important;
  line-height: 34px !important;
  text-align: left !important;
}
</style>