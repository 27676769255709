<template>
  <!-- <div class="main"> -->
  <div class="flex">
    <div class="col-lg-6 md-12 contact">
      <div class="maping">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.754177358495!2d0.0231709311633496!3d51.49937839900062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a86c198103b7%3A0xe4f6ec9ee2f444c9!2sLondon%20E16%202PG%2C%20UK!5e0!3m2!1sen!2s!4v1711429861509!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
    <div class="col-lg-5 col-md-12 contactus">
      <div>
        <h1 class="text-left mt-5">Contact Us</h1>
        <div class="names">
          <div class="form-group col-lg-6 col-md-12 col-sm-12 firstname">
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              placeholder="First Name"
              v-model="formData.fname"
            />
          </div>
          <div class="form-group col-lg-6 col-md-12 col-sm-12 lastname">
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              placeholder="Last Name"
              v-model="formData.lname"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            name="email"
            placeholder="E-mail"
            v-model="formData.email"
          />
        </div>
        <!-- <br /> -->

        <div class="form-group">
          <label for="phone">Phone Number</label>
          <input
            type="tel"
            class="form-control"
            id="phone"
            name="phone"
            placeholder="Phone Number "
            v-model="formData.phone"
          />
        </div>

        <div class="form-group">
          <label for="message"
            >Submit your information below and we'll send you an email soon.
            We're excited to meet you!</label
          >
          <textarea
            class="form-control"
            id="message"
            name="message"
            rows="3"
            v-model="formData.body"
          ></textarea>
        </div>
        <!-- <button  class="btn submitbtn">Submit information</button> -->
        <button type="submit" class="btn submitbtn" @click="submit()">
          Submit information
        </button>
      </div>
      <!-- </form> -->
    </div>
  </div>
  <!-- </div> -->
</template>
<script setup>
import { reactive } from "@vue/reactivity";
import axios from "axios";
const formData = reactive({
  fname: "",
  lname: "",
  email: "",
  phone: "",
  body: "",
});
const submit = async () => {
  await axios
    .post("https://lyceumgroupofschools.com/api/contact_toddlers", formData)
    .then((response) => {
      alert(response.data.message);
    });
};
</script>
<style scoped>
.main {
  margin: 60px 145px 0 90px;
}
.submitbtn {
  color: white;
  background: #e74c25;
  margin-top: 20px;
}
.names {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.firstname {
  padding-right: 10px;
}
.form-control {
  border: 1px solid #ededed;
  padding: 0.75rem 0.75rem;
}
.map {
  width: 100%;
  height: 500px;
  margin: 69px 0 0;
}
.form-group {
  margin-top: 15px;
}
.maping {
  margin: auto;
  justify-content: center;
  display: flex;
  margin-top: 85px;
}
.contactus {
  padding: 0px 20px 0 20px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
  .map {
    height: auto;
  }
  .firstname {
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .map {
    height: auto;
  }
  .contact {
    width: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 484px) {
  .firstname {
    width: 100%;
  }
  .lastname {
    width: 100%;
  }
}
</style>