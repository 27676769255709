<template>
  <div class="main">
	<!-- <div class="row col-12">
		<div class="col-lg-5 md-5 sm-12">
<img class="tvimg" src="../../assets/tvsNew.png" alt="">
		</div>
		<div class="col-1"></div>
		<div class="col-5 md-5 lg-12 bodyhead">
			<div class="body">
<h1 class="headtext">Profile</h1>
<p class="bodytext">Lyceum group is all about cultivating the crop of enlightened Leaders and become the name of Trust, Pride, Performance and Excellence. While we are proud of the history that we've built together, we challenge ourselves to be better tomorrow than we are today, and are constantly looking for ways to further serve our students and community.
Lyceum Group will continue to adjust to fully prepare the potential school graduates for advance career and for life in a global environment, but our consistent core values will continue to guide us into the future just as they have for the past 90 years.</p>
	</div>
		</div>
	</div> -->
	
  </div>
</template>
<style scoped>
.main{
	padding: 60px 30px 0 120px;
}
.headtext{
	font-family: 'Catamaran';
font-style: normal;
font-weight: 600;
font-size: 48px;
line-height: 66px;
margin-top: 115px;
align-items: center;
color: #0A303A;
}
.bodytext{
	font-family: 'Catamaran';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 27px;
margin-top: 30px;
align-items: center;
color: #333333;
text-align: justify;
}
.tvimg{
	width: -webkit-fill-available;
}
@media screen and (max-width:992px) {
	.main{
		width: 100%;
    margin: auto;
    padding: 30px;
	}
	.bodyhead{
		width: 100%;
    margin: auto;
	}
}
</style>