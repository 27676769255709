<template>
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent" style="z-index: 1 !important;">
      <!-- <div class="container-fluid"> -->
      <div class="d-flex togglebar">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
          aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <router-link to="/">
          <img class="togglelogo" src="https://thelyceumgroup.s3.ap-southeast-1.amazonaws.com/alisuk/logoalisuk.png" />
        </router-link>


      </div>
      <div class="collapse navbar-collapse " id="navbarText">
        <div><router-link to="/"><img class="logo"
              src="https://thelyceumgroup.s3.ap-southeast-1.amazonaws.com/alisuk/logoalisuk.png" /></router-link></div>
        <ul class="navbar-nav   navbar-collapse justify-content-end ">

          <li class="nav-item">
            <router-link :to="{ name: 'home' }"  class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/#vision" class="nav-link">Vision</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Register
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link :to="{ name: 'RegisterLondon' }" class="dropdown-item">London</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'RegisterManchster' }" class="dropdown-item">Manchster</router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'NewsPage' }" class="nav-link">News</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'EventPage' }" class="nav-link">Events</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Career' }" class="nav-link">Career</router-link>
          </li>
          <li class="nav-item">
            <router-link to="Advertisment" class="nav-link">Advertisment</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'projects' }"  class="nav-link">Projects</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'OurTeam' }" class="nav-link">Our Team</router-link>
          </li>
          <li class="nav-item">
            <router-link to="Gallery" class="nav-link">Gallery</router-link>
          </li>
          <li class="nav-item">
            <router-link to="Contactus" class=" btn btn-site m-4 bookvisit">
            Contact us
          </router-link>
          </li>

        </ul>
      </div>
      <!-- </div> -->
    </nav>
  </div>
</template>

<style scoped>

.btn-site
{
  background-color: #FA7B7A;
  color: #fff;
  font-size: 20px;
  
}

.row {
  width: 100%;
}

.logo {
  width: 120px;
  /* height: 10vw; */
}

.togglelogo {
  display: none;
  width: 10vw;
  height: 10vw;
}

.navbar-light .navbar-nav .nav-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 27px;
  color: #c87d3a;
  padding-left: 1.5vw;
  white-space: nowrap;
}

.dropdown-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #c87d3a;
  white-space: nowrap;
}

.dropdown-item:hover {
  color: #448b83;
  background: white;
}

.bookvisit {
  font-family: "Catamaran";
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #448b83;
}

.navbar-nav {
  padding-left: 0vw;
}

.navbar-number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height */
  color: #448b83;
}

.navbar-number {
  margin-left: 0.5vw;
}

.btn {
  width: fit-content;
}

.announcement {
  font-size: larger;
}

@media screen and (max-width: 1600px) {
  .navbar-light .navbar-nav .nav-link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    padding-left: 1.5vw;
  }

  .navbar-number {

    font-size: 12px;

  }
}

@media screen and (max-width: 1270px) {
  .navbar-light .navbar-nav .nav-link {
    font-family: "Poppins";
    font-style: normal;

    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    padding-left: 1.5vw;
  }
}

@media screen and (max-width: 1106px) {}

@media screen and (max-width: 1058px) {
  .navbar-light .navbar-nav .nav-link {
    font-family: "Poppins";
    font-style: normal;

    font-weight: 600;
    font-size: 10px;
    line-height: 27px;
    padding-left: 1vw;
  }

  .navbar-number[data-v-3f1ae435] {

    font-size: 12px;

  }
}

@media screen and (max-width: 1367) {
  .navbar-number[data-v-3f1ae435] {
    font-size: 15px;
  }
}

@media screen and (max-width: 992px) {


  .numberbtn {
    display: flex;
    flex-direction: column;
  }

  .navbar-nav {
    padding-left: 2vw;
  }

  .navbar-number {
    margin-left: 1vw;
  }

  .togglebar {
    width: 100%;
    justify-content: space-between;
  }

  .logo {
    display: none;
  }

  .togglelogo {
    display: block;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    background: linear-gradient(92deg, #ffffff, hsl(0deg 0% 100% / 60%));
    align-items: center;
    border-radius: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (min-width: 1007) {}
</style>
