<template>
  <NavBar />
  <Header />
  <!-- <Career/> -->

  <div class="container-fluid mt-5">
    <div class="px-lg-5">

      <!-- End -->

      <div class="row job-row">



        <div class="col-xl-6 col-lg-6 col-md-6 mb-4" v-for="(item, index) in ads" :key="index">

          <router-link :to="{ name: 'CareerDetail', params: { slug: item.slug } }">
            <div class="bg-white rounded shadow-sm card-main"><img :src="item.url" alt=""
                class="img-fluid card-img-top">
              <div>
                <div class="d-flex align-items-center justify-content-between bg-light px-3 p-4 ">
                  <h3 class="small mb-0"><i class="fa fa-picture-o mr-2 "></i><span
                      class="font-weight-bold text-success">Posted on: <em>{{ item.posted_date ?? '12-08-2023'
                        }}</em></span></h3>
                  <div class="badge badge-danger px-3 rounded-pill font-weight-normal text-success">
                    <i class="fas fa-eye"></i> {{ item.view_count ?? 432 }}
                  </div>
                </div>
              </div>
            </div>
          </router-link>


        </div>






      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import NavBar from "../components/layout/NavBar.vue";
import Header from "../components/Advertisment/HeaderAds.vue";
// import Career from "../pages/CareerPage.vue";
import Footer from "../components/layout/FooTer.vue";
export default {
  components: {
    NavBar,
    Header,
    // Career,
    Footer,
  },
};
</script>


<script setup>
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useGeneral } from "../store/index.js";

const general = useGeneral();
const { ads } = storeToRefs(general);

onMounted(() => {

  general.getData()
  
});

</script>

<style scoped>
.job-row {
  cursor: pointer;
}

.banner {
  background: #a770ef;
  background: -webkit-linear-gradient(to right, #a770ef, #cf8bf3, #fdb99b);
  background: linear-gradient(to right, #a770ef, #cf8bf3, #fdb99b);

}

.card-main {
  box-shadow: 0px 5px 20px rgba(34, 35, 58, 0.15)
}
</style>