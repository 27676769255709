<template>
  <NavBar />
  <Header />
  <!-- <Services/> -->
  <Footer />
</template>
  
  <script>
import NavBar from "../components/layout/NavBar.vue";
import Header from "../components/Services/HeaderPage.vue";
// import Services from "../components/Services/MainService.vue";
import Footer from "../components/layout/FooTer.vue";
export default {
  components: {
    NavBar,
    Header,
    // Services,
    Footer,
  },
};
</script>