<template>
  <div class="main">
    <div class="container-fluid">
      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="body">
            <div class="d-flex">
              <div>
                <h1 class="headtext" style="line-height: 25px">
                  Nursery in RoyalWharf, London
                </h1>
              </div>
              <!-- <img class="lycemlogo" src="../../assets/royal-wharf.jpg" alt="" /> -->
            </div>
            <p class="bodytext">
              American Lyceum Ltd, UK is embarking on an exciting venture with the imminent opening of 
              its inaugural nursery in the vibrant locale of Royal Wharf, London, UK. This significant 
              milestone marks the organization's entry into the educational landscape of the esteemed 
              city. Situated in the heart of Royal Wharf, our nursery will serve as a beacon of learning 
              and growth for young minds, offering a nurturing environment where children can thrive and 
              discover their potential. With a commitment to providing quality education and holistic 
              development, American Lyceum looks forward to enriching the lives of families and contributing 
              positively to the educational community of London.
            </p>
            <!-- <div class="btn btn-class">Learn more</div> -->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="imgbody">
            <img style="border-radius: 10px;" class="img" src="../../assets/royalwharf.jpg" alt="" />
          </div>
        </div>
      </div>

      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="imgbodyrevert">
            <img style="border-radius: 10px;" class="img" src="../../assets/liverpool.jpg" alt="" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="bodyrevert">
            <div class="d-flex">
              <div>
                <h1 class="headtext" style="line-height: 25px">
                  Nursery in Liverpool
                </h1>
              </div>
              <!-- <img class="lycemlogo" src="../../assets/nlogo.png" alt="" /> -->
            </div>
            <p class="bodytext">
              American Lyceum Ltd, UK is eagerly anticipating its forthcoming establishment in the vibrant 
              city of Merseyside, Liverpool. This exciting endeavor marks a significant expansion for the 
              organization, further solidifying its presence in the educational landscape of the United Kingdom. 
              Nestled in the dynamic surroundings of Merseyside, our upcoming venture embodies our dedication 
              to fostering educational excellence and holistic development. With a commitment to providing 
              a nurturing and enriching environment, American Lyceum aims to become a cornerstone of educational 
              innovation and community engagement in Liverpool. We eagerly anticipate the opportunity to serve 
              the diverse needs of families and contribute positively to the educational fabric of the city.
            </p>
            <!-- <div class="btn btn-class">Learn more</div> -->
          </div>
        </div>
      </div>

      <div class="card-bodys col-12">
        <div class="col-lg-6">
          <div class="body">
            <div class="d-flex">
              <div>
                <h1 class="headtext" style="line-height: 25px">
                  Nursery in Manchester
                </h1>
              </div>
              <!-- <img class="lycemlogo" src="../../assets/alis.png" alt="" /> -->
            </div>
            <p class="bodytext">
              American Lyceum Ltd, UK is on the brink of a new chapter as it prepares to inaugurate 
              its latest branch in the bustling region of Greater Manchester. This forthcoming expansion 
              represents a pivotal moment for the organization, signaling its unwavering commitment to 
              providing high-quality education and fostering intellectual growth across the United Kingdom. 
              Situated amidst the vibrant landscape of Greater Manchester, our upcoming branch is poised to 
              serve as a beacon of educational excellence, offering a nurturing environment where students 
              can thrive academically, socially, and emotionally. American Lyceum is thrilled to embark on 
              this journey of empowerment and enrichment, dedicated to shaping the minds of tomorrow and 
              contributing positively to the educational landscape of Greater Manchester.
            </p>
            <!-- <div class="btn btn-class">Learn more</div> -->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="imgbody">
            <img style="border-radius: 10px;" class="img" src="../../assets/manchester.jpg" alt="" />
          </div>
        </div>
      </div>


    </div>
  </div>
</template>
  
  <style scoped>
.main {
  position: relative;
  background-image: url("../../assets/background.png");
  /* height: 570px; */
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0px 0 0;
}
.body {
  padding: 0px 39px 15px;
  background: white;
  border-radius: 10px;
  width: 90%;
  text-align: justify;
  margin: 0 0 0 auto;
  z-index: 1;
  position: relative;
}
.bodyrevert {
  padding: 0px 39px 15px;
  background: white;
  border-radius: 10px;
  width: 90%;
  text-align: justify;
  margin: 0 0 0 -35px;
  z-index: 1;
  position: relative;
}
.lycemlogo {
  margin-top: 10px;
}
.d-flex {
  justify-content: space-between;
}
.imgbody {
  margin-left: -30px;
  margin-top: 130px;
}
.imgbodyrevert {
  /* width: 100%; */
  justify-content: right;
  display: flex;
  margin-top: 130px;
}
.card-bodys {
  display: flex;
  margin-top: 8%;
  margin-bottom: 20px;
}
.headtext {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 66px;
  /* margin-top: 30px; */
  align-items: center;
  color: #0a303a;
  padding-top: 20px;
}
.btn-class {
  border: 1px solid #e74c25;
  color: #e74c25;
  font-size: 14px;
  font-style: italic;
}
.bodytext {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-top: 10px;
  align-items: center;
  color: #333333;
}
@media screen and (max-width: 1542px) {
  .body {
    width: 100%;
  }
  .bodyrevert {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) {
  .img {
    width: -webkit-fill-available;
  }
}
@media screen and (max-width: 1320px) {
  /* .headtext {
      font-size: 25px;
    }
    .bodytext {
      font-size: 15px;
    } */
}
@media screen and (max-width: 1151px) {
  /* .headtext {
      font-size: 20px;
    }
    .bodytext {
      font-size: 16px;
      line-height: 20px;
    } */
}
@media screen and (max-width: 992px) {
  .card-bodys {
    display: flex;
    flex-direction: column;
  }
  .imgbody {
    margin-top: 50px;
    margin-left: 0px;
  }
  .body {
    margin-top: 30px;
  }
  .bodyrevert {
    margin: 0px;
    margin-top: 30px;
  }
  .bodytext {
    padding: 0px;
  }
  .headtext {
    line-height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .main {
    padding: 100px 0 0 0;
  }
}
@media screen and (max-width: 482px) {
  .headtext {
    font-size: 22px;
  }
  .body {
    padding: 0px 15px 15px;
  }
  .bodyrevert {
    padding: 0px 15px 15px;
  }
  .lycemlogo {
    width: fit-content;
  }
}
</style>