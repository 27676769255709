<template>
  <NavBar />
  <HeaderP />
  <Profile />
  <School />
  <Footer />
</template>

<script>
import NavBar from "../components/layout/NavBar.vue";
import HeaderP from "../components/project_page/HeaderPage.vue";
import Profile from "../components/project_page/LyceumProfile.vue";
import School from "../components/project_page/SchoolPage.vue";
import Footer from "../components/layout/FooTer.vue";
export default {
  components: {
    NavBar,
    HeaderP,
    Profile,
    School,
    Footer,
  },
};
</script>