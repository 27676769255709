import { defineStore } from "pinia";
import api from "../config/axios";

export const useGeneral = defineStore("general", {
    state: () => ({
        jobs_posts: [],
        ads: [],
        events: [],
        news: [],
        reg_london: [],
        reg_manchster: [],
        post_detail:''
    }),

    actions: {

        getData() {
            api.get("job-posts").then((response) => {
                this.jobs_posts = response.data.data.jobs;
                this.ads = response.data.data.ads;
                this.events = response.data.data.events;
                this.news = response.data.data.news;
                this.reg_london = response.data.data.reg_london;
                this.reg_manchster = response.data.data.reg_manchster;
            });
        },
        getSingleData(payload) {
            api.get("job-post/"+payload).then((response) => {
                this.post_detail = response.data.data;
            });
        },

    },
});
