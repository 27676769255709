import axios from "axios";


const api = axios.create({
    baseURL: 'https://admin.lyceumgroupofschools.com/api/v1/alisuk/general/',
    headers: {
        'Content-Type': 'application/json,multipart/form-data',
    },
});


api.interceptors.request.use(function (config) {

    return config;
});


export default api;
