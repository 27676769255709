<template>
  <NavBar />
  <Header />
  <Main />
  <Contact />
  <Footer />
</template>

<script>
import NavBar from "../components/layout/NavBar.vue";
import Header from "../components/ContactUs/HeaderPage.vue";
import Main from "../components/ContactUs/MainContact.vue";
import Contact from "../components/ContactUs/QuestionS.vue";
import Footer from "../components/layout/FooTer.vue";
export default {
  components: {
    NavBar,
    Header,
    Main,
    Contact,
    Footer,
  },
};
</script>