<template>
  <NavBar />
  <Header />
  <Main/>
  <Footer />
</template>
  
  <script>
import NavBar from "../components/layout/NavBar.vue";
import Header from "../components/Awards/HeaderAward.vue";
import Main from "../components/Awards/MainPage.vue";
import Footer from "../components/layout/FooTer.vue";
export default {
  components: {
    NavBar,
    Header,
    Main,
    Footer,
  },
};
</script>