<template>
  <NavBar />
  <Header />
  <Main />
  <Footer />
</template>

<script>
import NavBar from "../components/layout/NavBar.vue";
import Header from "../components/OurTeam/HeaderAdvisory.vue";
import Main from "../components/OurTeam/MainPageAdvisory.vue";
import Footer from "../components/layout/FooTer.vue";
export default {
  components: {
    NavBar,
    Header,
    Main,
    Footer,
  },
};
</script>
