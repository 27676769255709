<template>
  <div class="container">
    <div class="main">
      <img class="awardimage" src="../../assets/cert1.png" alt="" />
    </div>
  </div>
  <div class="text">
    <div class="head">
      <h1 class="headtext">ALCUMS AWARD</h1>
    </div>
    <div class="body">
      <p class="bodytext">
        This certificate will remain current subject to the company maintaining
        its system to the required standard. This will be monitored regularly by
        Alcumus ISOQAR. Further clarification regarding the scope of this
        certificate and the applicability of the relevant standards requirements
        may be obtained by consulting Alcumus ISOQAR
      </p>
    </div>
  </div>
  <div class="nextbtn">
    <router-link to="IsaAward" class="nav-link"
      ><img class="btn" src="../../assets/next.png" alt=""
    /></router-link>
  </div>
</template>
<style scoped>
.container {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.awardimage {
  width: 100%;
  margin: auto;
}

.head {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0 50px 0;
}
.body {
  width: 70%;
  display: flex;
  justify-content: center;
  margin: auto;
}
.nav-link{
	justify-content: end;
    display: flex;
}
.btn {
  width: 50%;
  /* margin: auto; */
  display: flex;
  justify-content: end;
}
.nextbtn {
  width: 95%;
  display: flex;
  justify-content: end;
  margin: 20px 0 35px 0;
}
.headtext {
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  line-height: 66px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #448b83;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bodytext {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;

  text-align: justify;

  color: rgba(0, 0, 0, 0.46);
}

@media screen and (max-width: 992px) {
  .headtext {
    font-size: 60px;
  }
  .bodytext {
    font-size: 30px;
  }
  .head {
    margin: 50px 0 30px 0;
  }
}

@media screen and (max-width: 610px) {
  .bodytext {
    font-size: 16px;
  }
  .headtext {
    font-size: 40px;
  }
  .head {
    margin: 50px 0 0 0;
  }
}
</style>