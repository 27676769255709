<template>
  <HeaderHome />
    <AboutUs />
 
  <Learning />
  <!-- <Ages /> -->
  <Footer />
</template>

<script>
import AboutUs from "../components/landing_page/AboutUs.vue";
import HeaderHome from "../components/landing_page/HeaderHome.vue";
import Learning from "../components/landing_page/LearningEnvironment.vue";
// import Ages from "../components/landing_page/AgEs.vue";
import Footer from "../components/layout/FooTer.vue";
export default {
  components: {
    AboutUs,
    HeaderHome,
    Learning,
    // Ages,
    Footer,
  },
};
</script>
