<template>
  <div
    class="
      row
      p-5
      row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-sm-2
      g-4
      mx-auto
      mb-5
    "
  >
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #09a983"></div>
        <img src="../../assets/maria.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Ms.Maria Belen</h1>
          <p class="card-text">
            <span v-if="showMore1">
              Holds a bachelor's degree in Educational Sciences and is a
              certified educationist from Harvard Business School. Specializes
              in school management and leadership. She runs organizations and
              led successful projects in schools, foundations, universities, and
              language schools.
              <span @click="showMore1 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore1 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #09a983"></div>
        <img src="../../assets/shohail.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Dr. Sohail Qureshi</h1>
          <p class="card-text">
            <span v-if="showMore5">
              Dr. Qureshi earned his doctorate in Molecular Biology in 1989 from
              the University of Maine-Orono (USA) and subsequently carried out
              his postdoctoral research work at Glasgow University, Imperial
              Cancer Research Fund (London), and Cambridge University. Having
              worked at the Universities of Glasgow, Cambridge,
              Wisconsin-Madison, and Invitrogen Corporation, his post-PhD
              research, teaching, and corporate experience spans a period of
              over 20 years. He has published a number of seminal papers in
              top-tier scientific journals. His main areas of research are
              regulation of gene expression in extremophilic archaea and
              hepatitis-C.
              <span @click="showMore5 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore5 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #c77d30"></div>
        <img src="../../assets/safee.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Dr. Saffee Ullah</h1>
          <p class="card-text">
            <span v-if="showMore8">
              Assistant Professor, School of Science and Engineering
              <span @click="showMore8 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore8 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #d8655b"></div>
        <img src="../../assets/puja.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Ms. Puja Seth</h1>
          <p class="card-text">
            <span v-if="showMore10">
              Specialize in Education Administration. Specialize in Talent
              Lifecycle Management and in Academic Coordination. Certified from
              Harvard School of Education
              <span @click="showMore10 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore10 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <!-- Second 4 cards -->

    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #d8655b"></div>
        <img src="../../assets/jafery.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Jeffrey R.</h1>
          <h1 class="bootomtext">Quebec, M.ED</h1>
          <p class="card-text">
            <span v-if="showMore2">
              A dedicated and accomplished Senior Executive Administrator with a
              proven and recognized track record as a successful leader in
              education. Syracuse University (BS).Boston University (M.Ed.).
              Northeastern University (Ed.D. Candidate).Holds Certifications
              from Boston University, Vanderbilt University, Stanford
              University, University of Pennsylvania, & Harvard University
              <span @click="showMore2 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore2 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #da9d94"></div>
        <img src="../../assets/noiclo.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Ms.Nicola</h1>
          <h1 class="bootomtext">Waterson</h1>
          <p class="card-text">
            <span v-if="showMore3">
              Certified Educationist from Harvard School of Education. Learning
              Support Coordinator at San Silvestre School, Peru. Highly
              qualified & experienced School Leader, Inclusion Manager &
              outstanding Specialist Teacher with a passion for creating an
              engaging and inclusive learning environment for all pupils.
              <span @click="showMore3 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore3 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #c77d30"></div>
        <img src="../../assets/usman.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">DR. M.</h1>
          <h1 class="bootomtext">Usman Awan</h1>
          <p class="card-text">
            <span v-if="showMore4">
              Dean, IQTM, Punjab University, Pakistan. Have 21 Publications and
              have a keen interest in career counseling.
              <span @click="showMore4 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore4 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #d8655b"></div>
        <img src="../../assets/haider.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Mr. Syed Haider</h1>
          <h1 class="bootomtext">Ali Indonesia</h1>
          <p class="card-text">
            <span v-if="showMore6">
              Ph.D. from the University of Manchester, Assistant Professor
              COMSATS Institute of Information Technology. Thorough
              Educationist.
              <span @click="showMore6 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore6 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #da9d94"></div>
        <img src="../../assets/atif.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Dr. Atif Shahbaz</h1>
          <h1 class="bootomtext">Germany</h1>
          <p class="card-text">
            <span v-if="showMore7">
              M.Phil (PU) Ph.D (Germany) Post Doc (Germany)
              <span @click="showMore7 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore7 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #09a983"></div>
        <img src="../../assets/alicia.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Ms. Alícia Nakashima</h1>
          <h1 class="bootomtext">Villarreal</h1>
          <p class="card-text">
            <span v-if="showMore9">
              Alícia is a passionate educator. She holds a bachelor's in
              Economics from Tec de Monterrey and an MSc in International
              Management from HEC Lausanne. Certifícate in school Management -
              Harvard
              <span @click="showMore9 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore9 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #da9d94"></div>
        <img src="../../assets/ali.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Dr. Muhammad</h1>
          <h1 class="bootomtext">Ali</h1>
          <p class="card-text">
            <span v-if="showMore11">
              Assistant Professor, Government College University, Lahore,
              Pakistan Author of 4 books and 19 research publications.
              <span @click="showMore11 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore11 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header" style="background-color: #c77d30"></div>
        <img src="../../assets/musin.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Mr. Muhsin Ali</h1>
          <h1 class="bootomtext">Saudi Arabia</h1>
          <p class="card-text">
            <span v-if="showMore12">
              Saudi Arabia . Alumni Harvard School of Education. English
              Teacher,Ozel Antalya Koleji Instructional Facilitator, King Faisal
              Foundation. Business Studies, History and Geography Teacher,
              R.E.A.L International School, Malaysia
              <span @click="showMore12 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore12 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="col">
      <div class="card h-100 secondlastcard">
        <div class="card-header" style="background-color: #09a983"></div>
        <img src="../../assets/arvindaaa.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Arvind Kumar</h1>
          <h1 class="bootomtext">Chalasani</h1>
          <p class="card-text">
            <span v-if="showMore13">
              Head of Beacon Academy, Jakarta, Indonesia. International
              Educationist with experience in curriculum design, teachers
              training, and school management. Certified from Harvard School of
              Education.
              <span @click="showMore13 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore13 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100 lastcard">
        <div class="card-header" style="background-color: #d8655b"></div>
        <img src="../../assets/sid.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <h1 class="bootomtext">Siddharth</h1>
          <h1 class="bootomtext">Rajgarhia</h1>
          <p class="card-text">
            <span v-if="showMore14">
              Harvard Business School Distance learning Educational Leadership
              and Administration,
              <span @click="showMore14 = false" class="more"
                >show less...</span
              ></span
            >
            <span v-else @click="showMore14 = true" class="more">
              <br />View Detail...</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
let showMore1 = ref(false);
let showMore2 = ref(false);
let showMore3 = ref(false);
let showMore4 = ref(false);
let showMore5 = ref(false);
let showMore6 = ref(false);
let showMore7 = ref(false);
let showMore8 = ref(false);
let showMore9 = ref(false);
let showMore10 = ref(false);
let showMore11 = ref(false);
let showMore12 = ref(false);
let showMore13 = ref(false);
let showMore14 = ref(false);
</script>

<style scoped>
.more {
  color: green;
  cursor: pointer;
}
.card {
  margin-top: 3%;
  width: 95%;
  border-radius: 12px;
}
.Main {
  transform: translateX(-30vw);
  margin-top: -18vw;
  margin-bottom: 20vw;
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 70%;
  margin: auto;
}
.childimage {
  width: 100%;
}
.advisorytext {
  font-weight: 600;
  font-size: 80px;
  line-height: 66px;
}
.boardtext {
  font-weight: 600;
  font-size: 120px;
  line-height: 120px;
}
.smalltext {
  font-family: "Catamaran";
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  transform: translateX(10vw);
}
.card-text {
  font-family: "Catamaran";
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  font-family: serif;
  text-align: justify;
  color: #787878;
  justify-content: center;
  display: flex;
}
.bootomtext {
  font-family: "Catamaran";
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
}
.secondlastcard {
  margin-left: 24vw;
  /* width: 100%; */
}
.lastcard {
  margin-left: 24vw;
  /* width: 100%; */
}
/* Media Quires Start */
@media screen and (min-width: 1400px) {
  .advisorytext {
    font-size: 56px;
    line-height: 29px;
  }
  .boardtext {
    font-size: 89px;
    line-height: 100px;
  }
}
@media screen and (max-width: 992px) {
  .secondlastcard {
    margin-left: 16vw;
  }
  .lastcard {
    margin-left: 18vw;
  }
}
@media screen and (max-width: 768px) {
  .secondlastcard {
    margin-left: 0vw;
  }
  .lastcard {
    margin-left: 0vw;
  }
}
</style>

