<template>
  <div class="main">
    <NavBar />

    <div class="ncc-section blink">
      <div class="ncc-inner">
          <a class="first-ncc" href="https://www.fsb.org.uk/" target="_blank">
            <img src="https://www.fsb.org.uk/assets/img/fsb-logo.svg" />
            <h3>Member of Federation of Small Business</h3>
          </a>
          <a class="second-ncc" href="https://newhamchamber.com/" target="_blank">
            <img src="../../../src/assets/logos/newham_chamber.png" />
            <marquee onmouseover="this.stop();" onmouseout="this.start();"><h3>Member of Newham Chamber of Commerce</h3></marquee>
          </a>
      </div>
    </div>


  </div>

</template>
<script>
import { ref, onMounted } from "vue";
import NavBar from "../../components/layout/NavBar.vue";

export default {
  components: {
    NavBar,
  },
  setup() {
    const showModal = ref(true);

    onMounted(() => {
      showModal.value = true;
    });

    const closeModal = () => {
      showModal.value = false;
    };

    return { showModal, closeModal };
  },
};
</script>
<style scoped>

.blink {
  animation: blink 2s linear infinite;
  -webkit-animation: blink 2s linear infinite;
}
@keyframes blink {
  25% {
    opacity: 1;
  }
  
  25% {
    opacity: 0.9;
  }

  75% {
    opacity: 0.8;
  }
}

.ncc-section
{

  

  text-align: center;
  position: absolute;
  right: 0;
  width: 350px;
  background-color: rgba(0, 0, 0, 1);
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  padding: 8px 20px;
  border: 5px double #FA7B7A;
  border-right: 0px;
}
.ncc-section img
{
  width: 20%;
  padding: 2px
}
.ncc-section .second-ncc img
{
  width: 54%;
  padding: 0px !important;
}
.ncc-section h3
{
    font-size: 18px !important;
    color: #FA7B7A;
    font-family: "Poppins";
    padding-top: 8px;

}
.ncc-section a
{
  text-decoration: none;
}


.main {
  position: relative;
  background-image: url("https://thelyceumgroup.s3.ap-southeast-1.amazonaws.com/alisuk/Group.png");
  height: 125vh;
  background-size: cover;
}

.logo {
  width: 5vw;
  height: 5vw;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: none;
  border-bottom-right-radius: none;
  border-bottom-left-radius: none;
  background-color: lightblue;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  /* align-items: center; */
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
  background-color: lightblue;
  text-align: center;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  background-color: lightblue;
}

.modal-title {
  text-align: center;
}

.main-banner-text {
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
  .alis {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .member {
    display: none;
  }

  .alis {
    font-size: px;
  }
}
</style>
