<template>
    <div class="main">
        <div class="body">
            <h1 class="headtext" style="font-size: 70px;">Upcoming Projects</h1>
            <p class="bodytext">
                &nbsp;&nbsp;American Lyceum Ltd, UK is launching the following projects:
            </p>
        </div>
    </div>
</template>
  
<style scoped>
.main {
    position: relative;
    background-image: url("../../assets/projhead.png");
    height: 570px;
    background-size: cover;
    background-repeat: no-repeat;
}

.body {
    padding: 225px 0 0 100px;
}

.headtext {
    font-family: "Catamaran";
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 66px;
    margin-top: 30px;
    align-items: center;
    color: #0a303a;
}

.bodytext {
    font-family: "Catamaran";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    margin-top: 30px;
    align-items: center;
    color: #333333;
}

@media screen and (max-width: 992px) {
    .body {
        padding: 225px 0 0 30px;
    }

    .headtext {
        font-size: 85px;
    }
}

@media screen and (max-width: 767px) {
    .headtext {
        font-size: 75px;
    }
}
</style>